import styled from 'styled-components';

import vars from 'variables';

import logo from 'assets/Common/Logo/hodl.svg';
import logoBlack from 'assets/Common/Logo/black.svg';
import logoWhite from 'assets/Common/Logo/white.svg';

import downBlack from 'assets/Layout/Header/Menu/NavBar/down-black.svg';
import downWhite from 'assets/Layout/Header/Menu/NavBar/down-white.svg';
import english from 'assets/Layout/Header/Menu/NavBar/english.svg';
import upBlue from 'assets/Layout/Header/Menu/NavBar/up-blue.svg';
import right from 'assets/Layout/Header/Menu/NavBar/right.svg';

const Root = styled.div`
  position: relative;

  body.fixed & {
    background: ${vars.colors.background};
  }

  body.fixed [data-section] & {
    background: none;
  }

  body[path^='${vars.pages.claim}'] & .hidable {
    display: none !important;
  }

  a.index-logo {
    height: 41px;
    pointer-events: auto;
    position: absolute;
    background: url(${logo}) no-repeat;
    background-size: contain;
  }

  #menu-buttons {
    text-align: right;
  }

  #menu-buttons div[class] * {
    pointer-events: auto;
  }

  nav * {
    pointer-events: auto;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding: 30px;
    }

    body[path^='${vars.pages.dashboard}'] header:not([data-section]) & {
      background: ${vars.colors.marineBlue};
    }

    body.fixed & {
      border-bottom: 2px solid rgb(32, 34, 56);
    }

    body.fixed [data-section] & {
      border-bottom: 2px solid white;
    }

    a.index-logo {
      width: 138px;
      top: 38px;
    }

    #menu input,
    #menu span {
      display: none;
    }

    nav {
      margin-left: 138px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      height: 25px;
      padding: 14px 12px;
    }

    body.fixed & {
      border-bottom: 1px solid rgb(32, 34, 56);
    }

    body.fixed [data-section] & {
      border-bottom: 1px solid white;
    }

    a.index-logo {
      width: 83px;
    }

    a.mobile.index-logo {
      width: 100vw;
      height: 55px;
      position: fixed;
      background-color: white;
      background-image: url(${logoBlack});
      background-position: 12px 14px;
      background-size: 82px;
    }

    #menu {
      top: 19px;
      right: 19px;
      display: block;
      position: absolute;
      user-select: none;
      z-index: 3;
    }

    #menu a {
      text-decoration: none;
      transition: color 0.3s ease;
    }

    #menu input {
      top: -7px;
      left: -5px;
      width: 40px;
      height: 32px;
      cursor: pointer;
      display: block;
      position: absolute;
      opacity: 0;
      z-index: 3;
      -webkit-touch-callout: none;
    }

    #menu span {
      width: 20px;
      height: 2px;
      margin-bottom: 4px;
      display: block;
      position: relative;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
      background: ${vars.colors.white};
      border-radius: 3px;
      z-index: 2;
    }

    #menu span:first-child {
      transform-origin: 0% 0%;
    }

    #menu span:nth-last-child(2) {
      transform-origin: 0% 100%;
    }

    #menu input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(5px, -4px);
      background: black;
    }

    #menu input:checked ~ span:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    #menu input:checked ~ span:nth-last-child(2) {
      transform: rotate(-45deg) translate(6px, 5px);
    }

    #menu input:checked ~ nav {
      transform: none;
    }

    nav {
      width: 100vw;
      height: 100vh;
      margin-top: -37px;
      margin-left: calc(-100vw + 39px);
      overflow-y: scroll;
      position: absolute;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      transform: translate(-100vw, 0);
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      background: white;
    }

    nav a {
      color: black;
    }
  }
`;

const sections = vars.navigation
  .map(
    ({ identifier }) =>
      `[data-section="${identifier}"] & a[data-section="${identifier}"]`
  )
  .join(',\n');

const Sections = styled.div`
  a {
    text-transform: uppercase;
  }

  body[path^='${vars.pages.claim}'] & {
    display: none;
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-left: 10px;
    }

    a {
      margin-left: 30px;
      padding-right: 15px;
      font-size: 0.85rem;
    }

    a:hover {
      cursor: pointer;
    }

    a[data-section] {
      background: url(${downWhite}) no-repeat right center;
    }

    [data-section] & a[data-section] {
      background-image: url(${downBlack});
    }

    ${sections} {
      background-image: url(${upBlue});
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding-top: 70px;
      background: rgb(248, 250, 251);
    }

    a {
      width: calc(100vw - 40px);
      margin: 0 auto;
      display: block;
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 65px;
      background: url(${right}) no-repeat right center;
      background-size: 6px;
      border-bottom: 1px solid rgb(245, 241, 241);
    }
  }
`;

const Buttons = styled.div`
  margin-left: auto;

  a.locale {
    padding-left: 28px;
    padding-right: 15px;
  }

  a.locale.eng {
    background-image: url(${english}), url(${downWhite});
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
  }

  a.button {
    margin-left: 18px;
  }

  ${vars.desktop.mediaQuery} {
    a.locale {
      margin-right: 20px;
    }

    [data-section] & .logo,
    [data-section] & .logo-x {
      color: ${vars.colors.white};
      background-color: ${vars.colors.blue};
    }

    [data-section] & .logo {
      background-image: url(${logoWhite});
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding: 0 20px 85px 20px;
      text-align: center;
    }

    a.locale {
      font-size: 0.85rem;
      font-weight: 600;
    }

    a.locale.eng {
      background-image: url(${english}), url(${downBlack});
    }

    a.button {
      margin: 0 auto;
      margin-bottom: 7px;
      display: block;
    }

    a.button.logo,
    a.button.logo-x {
      margin-top: 0;
      color: ${vars.colors.white};
      background-color: ${vars.colors.blue};
    }

    a.button.logo {
      background-image: url(${logoWhite});
      background-position: left calc(50% - 36px) center;
    }

    a.button.logo-x {
      background-position: left calc(50% - 41px) center;
      background-size: 16px 16px;
    }

    a.button.blue {
      background-color: ${vars.colors.marineBlue};
    }

    .logo-bnb {
      padding-left: 37px;
      background-position: left calc(50% - 36px) center;
      background-size: 16px 16px;
    }
  }
`;

export { Root, Sections, Buttons };
