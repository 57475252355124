import React from 'react';
import { Root } from './style';

const Section = ({ section: { identifier, name, columns } }) => {
  const onHeaderClick = () => {
    const header = document.querySelector('header[data-section]');
    header && header.removeAttribute('data-section');
  };

  const replacements = new Map([
    ['WORLD', '🌎'],
    ['DOCUMENT', '📄'],
    ['COMPASS', '🧭'],
    ['BIONIC_ARM', '🦾'],
    ['DOWNLOAD', '⬇️'],
    ['TEAM', '👥'],
    ['ORANGE_DIAMOND', '🛒'],
    ['BUY', '🛍️'],
    ['CALCULATOR', '🔢'],
    ['KING', '👑'],
    ['CHART', '📈'],
    ['RANKINGS', '🐋'],
    ['GAMING', '🎮'],
    ['SWORDS', '⚔️'],
    ['CARDS', '🃏'],
    ['PICKAXE', '⛏️'],
    ['PIGGYBANK', '🐖'],
    ['ROCKET', '🚀'],
    ['HODL_HANDS', '✊🏻'],
    ['EARN', '💰'],
    ['GEM', '💎'],
    ['APP', '📱'],
    ['ANDROID', '🤖'],
    ['APPLE', '🍏'],
    ['LITE', '🌟'],
    ['PRO', '🥷'],
    ['SECURE', '🔒'],
    ['CHAT', '💬'],
    ['X', '✖️'],
    ['BINANCE_SQUARE', '🟧'],
    ['REDDIT', '👽'],
    ['CASE_STUDIES', '📖'],
    ['CHARITY', '❤️'],
    ['NEWS', '📰'],
    ['FAQS', '❓'],
    ['MERCH', '🧢'],
    ['PARTNERS', '🤝'],
    ['ORIGIN', '📜'],
    ['VIDEOS', '🎥'],
    ['DASHBOARD', '📊'],
  ]);

  function replacePlaceholders(text) {
    return text.replace(
      /\{(.*?)\}/g,
      (_, key) => replacements.get(key) || `{${key}}`
    );
  }

  function getUrl(url) {
    return url?.replace('https://hodltoken.net', '');
  }

  return (
    <Root data-menu={identifier}>
      <h4 onClick={onHeaderClick}>{name}</h4>
      <div className="wrapper">
        {columns.map((column, index) => (
          <ul key={index}>
            {column.name ? (
              <li>
                <strong>{column.name}</strong>
              </li>
            ) : (
              <li className="empty" />
            )}
            {column.links.map(
              ({ label, url, description, quicklink }, index) => {
                if (quicklink) {
                  return (
                    <li key={index} className="quicklink">
                      <a href={getUrl(url)}>{label}</a>
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <a href={getUrl(url)}>{label}</a>
                      {description ? (
                        <p>{replacePlaceholders(description)}</p>
                      ) : (
                        ''
                      )}
                    </li>
                  );
                }
              }
            )}
          </ul>
        ))}
      </div>
    </Root>
  );
};

export default Section;
