import { createGlobalStyle } from 'styled-components';

import bsc from 'assets/Common/Logo/bsc.svg';
import close from 'assets/Common/ReactModal/close.svg';
import download from 'assets/Common/Logo/download.svg';
import logoBlue from 'assets/Common/Logo/blue.svg';
import logoWhite from 'assets/Common/Logo/white.svg';
import logoX from 'assets/Common/Logo/hodlx.png';
import logoBnb from 'assets/Common/Logo/bnb.svg';
import telegram from 'assets/Common/Socials/telegram.svg';

import elementsDesktop from './elements/desktop';
import elementsMobile from './elements/mobile';

import backgroundsDesktop from './backgrounds/desktop';
import backgroundsMobile from './backgrounds/mobile';
import backgroundsScaled from './backgrounds/scaled';

import vars from 'variables';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }

  *:focus {
    outline: none;
  }

  body {
    color: ${vars.colors.white};
    font-family: ${vars.fonts.default};
    line-height: 195%;
    background: ${vars.colors.background};
  }

  body[path^="${vars.pages.claim}"] {
    background-color: rgb(13, 15, 35);
  }

  .body-width {
    margin-left: auto;
    margin-right: auto;
  }

  .adroll_persistent_icon_wrapper {
    width: auto !important;
    height: 26px !important;
    bottom: 0;
  }

  h1, h2 {
    font-weight: 900;
  }

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }

  h2 {
    font-size: 4rem;
  }

  h1 span,
  h2 span,
  h3 span,
  h4 span,
  p span,
  p strong {
    color: ${vars.colors.blue};
  }

  li {
    list-style: none;
  }

  a {
    color: ${vars.colors.white};
    text-decoration: none;
  }

  a.button {
    color: ${vars.colors.black};
    display: inline-block;
    cursor: pointer;
    font-family: ${vars.fonts.default};
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    background: white;
    border: none;
    border-radius: 9px;
  }

  a.button.blue {
    color: ${vars.colors.white};
    background-color: ${vars.colors.blue};
  }

  a.button.marine-blue {
    color: ${vars.colors.white};
    background-color: ${vars.colors.marineBlue};
  }

  a.button.logo {
    background-image: url(${logoBlue});
    background-repeat: no-repeat;
  }

  a.button.logo-bnb {
    background-image: url(${logoBnb});
    background-repeat: no-repeat;
  }

  a.button.logo-x {
    background-size: 20px 20px;
    background-image: url(${logoX});
    background-repeat: no-repeat;
  }

  a.button.blue.logo {
    background-image: url(${logoWhite});
  }

  a.button.blue.bsc {
    background-image: url(${bsc});
    background-repeat: no-repeat;
  }

  a.button.blue.download {
    background-image: url(${download});
    background-repeat: no-repeat;
  }

  a.button.blue.telegram {
    background-image: url(${telegram});
    background-repeat: no-repeat;
  }

  a.button.transparent.logo {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    background-image: url(${logoWhite});
    border: 2px solid ${vars.colors.white};
  }

  #__gatsby {
    min-height: 100vh;
    position: relative;
  }

  .rm-modal {
    background-color: rgb(33, 35, 64);
  }

  .rm-modal h3 {
    text-align: center;
  }

  .rm-close {
    margin-left: auto;
    cursor: pointer;
    background: url(${close}) no-repeat;
    background-size: cover;
  }

  .rm-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(0, 3, 22, 0.85);
    z-index: 6;
  }

  .adroll_consent_persistent_icon {
    margin-top: -57px !important;
    right: auto !important;
  }

  .adroll_persistent_icon_wrapper > span {
    transform: rotate(180deg);
  }

  .adroll_persistent_icon_wrapper > span > svg {
    width: 24px !important;
    height: 24px !important;
  }

  .web3modal-modal-lightbox {
    z-index: 7 !important;
  }

  ${vars.desktop.mediaQuery} {
    ${elementsDesktop}
  }

  ${vars.mobile.mediaQuery} {
    ${elementsMobile}
  }

  ${vars.desktop.backgroundsMediaQuery} {
    ${backgroundsDesktop}
  }

  ${vars.mobile.backgroundsMediaQuery} {
    ${backgroundsMobile}
  }

  ${vars.scaled.backgroundsMediaQuery} {
    ${backgroundsScaled}
  }
`;

export default GlobalStyle;
