import React, { createContext, useContext, useEffect, useState } from 'react';

import { EthereumProvider } from '@walletconnect/ethereum-provider';
import moment from 'moment';

import {
  bnbABI,
  busdABI,
  hodlABI,
  hodlTestABI,
  energyABI,
  pancakeSwapAbi,
  buyHodlhandAbi,
  hodlhandsAbi,
} from './abi';

import hodl from 'assets/Common/Logo/blue.svg';

import { localStorageGet, localStorageSet } from 'utils';
import vars from 'variables';

export let USEMAINNET,
  CHAINID,
  RPCURL,
  CONTRACTADDRESS,
  CONTRACTABI,
  BTCADDRESS,
  WBNBADDRESS,
  LPADDRESSES,
  BUSDADDRESS,
  BUYENERGYADDRESS,
  PCSROUTER,
  HODLXROUTER,
  USDADDRESS,
  BUYHODLHANDCONTRACT,
  HODLHANDSCONTRACT;

let contract, reinvestTokens, web3;
const projectFundsWallets = [
  {
    name: 'treasury',
    address: '0x29cC45Dc85A08350BcFf072805E032e97E0e7BEB',
  },
  {
    name: 'rewardpool',
    address: '0x1F1b37947c63f63B08B2dbfcC9225858CBE7A213',
  },
  {
    name: 'liquidity',
    address: '0xC5C914fbdDeA7270051EDC1dd57c0Ac9621A52dc',
  },
  {
    name: 'mmfunds',
    address: '0x390a8C324D1B343280c25E1BD8B4F178e6429D4c',
  },
];

if (typeof window !== 'undefined') {
  window.switchToNetwork = (useTestNet) => {
    USEMAINNET = !useTestNet;

    if (USEMAINNET) {
      // BSC Main Net
      CHAINID = 56;
      RPCURL = 'https://bsc-dataseed.binance.org';
      CONTRACTADDRESS = '0x32b407ee915432be6d3f168bc1eff2a6f8b2034c';
      CONTRACTABI = hodlABI;
      WBNBADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
      LPADDRESSES = ['0xC5c4F99423DfD4D2b73D863aEe50750468e45C19'];
      BUSDADDRESS = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
      BUYENERGYADDRESS = '0x3FFEd77a280a18Ccb49dBD4d8475A6A3232778Be';
      PCSROUTER = '0x10ED43C718714eb63d5aA57B78B54704E256024E'.toLowerCase();
      USDADDRESS = '0x55d398326f99059fF775485246999027B3197955';
      BUYHODLHANDCONTRACT = '0x7e82123bcb6465133d6e9e1ad94d0115de041b3d';
      HODLHANDSCONTRACT = '0x7E82123bCb6465133D6E9E1Ad94d0115DE041b3D';
      BTCADDRESS = '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c';
    } else {
      // BSC Test Net
      CHAINID = 97;
      RPCURL = 'https://data-seed-prebsc-1-s1.binance.org:8545';
      CONTRACTADDRESS = '0x7045028e2b0fcd32e3e8973d26d1fdf20fb97719';
      CONTRACTABI = hodlTestABI;
      WBNBADDRESS = '0xae13d989dac2f0debff460ac112a837c89baa7cd';
      LPADDRESSES = ['0xae13d989dac2f0debff460ac112a837c89baa7cd'];
      BUSDADDRESS = '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee';
      BUYENERGYADDRESS = '0x27abFD055c46D36Cd3E338ed3A7B3Ce5BEE33A47';
      PCSROUTER = '0xD99D1c33F9fC3444f8101754aBC46c52416550D1';
      HODLXROUTER = '';
      USDADDRESS = '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814';
      BUYHODLHANDCONTRACT = '';
      HODLHANDSCONTRACT = '';
    }

    reinvestTokens = {
      hodl: {
        label: '$HODL',
        value: 'hodl',
        description:
          'Pay no tax (usually 10%) when reinvesting and get a {BONUS}% bonus in $HODL!',
        image: hodl,
        contract: CONTRACTADDRESS,
      },
    };

    web3 = typeof window !== 'undefined' && new window.Web3(RPCURL);
    contract = web3 && new web3.eth.Contract(CONTRACTABI, CONTRACTADDRESS);
  };

  window.switchToNetwork();
}

export const INJECTED = 0;
export const WALLETCONNECT = 1;

const Web3Context = createContext({
  bsc: {},
  wallet: {},
});

const mapAsync = async (collection, func) => {
  let promises = collection.map((item) => {
    if (func.constructor.name !== 'AsyncFunction') {
      return new Promise((resolve, reject) => {
        try {
          resolve(func(item));
        } catch (e) {
          reject(e);
        }
      });
    } else {
      return func(item);
    }
  });
  return await Promise.all(promises);
};

export const useWeb3 = () => useContext(Web3Context);

export const Web3Provider = ({ children }) => {
  const [bsc, setBsc] = useState(localStorageGet('bscData', {}));
  const [wallet, setWallet] = useState('');
  const [connected, setConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const web3client = async (provider) => {
    let walletProvider, web3;
    try {
      switch (Number(provider)) {
        case INJECTED:
          walletProvider =
            window.ethereum ||
            (window.web3 ? window.web3.currentProvider : null);
          if (!walletProvider) {
            setIsConnecting(false);
            throw new Error('No wallet detected in browser');
          }
          web3 = new window.Web3(walletProvider);
          await web3.eth.requestAccounts();
          break;
        case WALLETCONNECT:
          walletProvider = await EthereumProvider.init({
            projectId: 'bc989e48daa6175da379eb80666562e4',
            metadata: {
              name: 'HODL',
              description:
                '$HODL: THE ULTIMATE REWARD TOKEN!\n\nEarn maximum passive income with $HODL on Binance Smart Chain! Its innovative contract incurs a 5% tax on every transaction, with a sell bot generating BNB rewards for all holders. Claim your share every 7 days.',
              url: 'https://hodltoken.net/',
              icons: ['https://hodltoken.net/icons/icon-96x96.png'],
            },
            showQrModal: true,
            optionalChains: [56, 97],

            rpcMap: {
              [CHAINID]: RPCURL,
            },
          });
          await walletProvider.enable();
          web3 = new window.Web3(walletProvider);
          walletProvider.on('accountsChanged', unsetWalletAddress);
          /*
          WalletConnectProvider({
            rpc: { [CHAINID]: RPCURL },
            pollingInterval: 12000,
          });
          await walletProvider.enable();
          web3 = new window.Web3(walletProvider);
          */
          break;
        default:
      }
    } catch (error) {
      setIsConnecting(false);
      if (error.message.includes('Connection request reset.')) web3 = 'closed';
    }

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', unsetWalletAddress);
    }
    return web3;
  };

  const activate = async () => {
    setIsConnecting(true);
    const provider = localStorageGet('provider');

    if (
      typeof provider !== 'undefined' &&
      window.sessionStorage.getItem('walletAddress')
    ) {
      const web3 = await web3client(provider);
      if (web3) {
        const accounts = await web3.eth.getAccounts();
        await setWalletAddress(provider, accounts, web3);
      }
    }
    setIsConnecting(false);
  };

  const connect = async (provider) => {
    if (provider === undefined) {
      provider = localStorageGet('provider');
    } else {
      setIsConnecting(true);
    }
    const web3 = await web3client(provider);
    if (web3 === 'closed') {
      throw {
        message: 'closed',
      };
    } else if (!web3) {
      throw {
        message: 'No wallet found',
        submessage:
          "Connect via your wallet's built-in browser, WalletConnect, or a browser extension like MetaMask",
      };
    }
    const chainId = await web3.eth.net.getId();
    window.switchToNetwork();

    try {
      if (chainId !== CHAINID) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (error) {
          setIsConnecting(false);
          throw new Error('Network change REJECTED');
        }
      }
      const accounts = await web3.eth.getAccounts();
      await setWalletAddress(provider, accounts, web3);
      setConnected(true);
      setIsConnecting(false);
      return accounts[0];
    } catch (e) {
      console.log('e', e);
      unsetWalletAddress(web3);
      setConnected(false);
      setIsConnecting(false);
      throw e;
    }
  };

  const setWalletAddress = async (provider, accounts, web3) => {
    const account = accounts && accounts[0];

    if (account) {
      localStorageSet('provider', provider);
      window.sessionStorage.setItem('walletAddress', account);

      let wallet = localStorageGet('walletData', {});
      if (wallet.account === account) {
        setWallet(wallet);
      }

      const nextClaimDateEpoch = await getNextClaimDate(account);
      const nextClaimDate =
        nextClaimDateEpoch > 0 &&
        moment.unix(nextClaimDateEpoch).format('ddd MMM D YYYY') +
          ' at ' +
          moment.unix(nextClaimDateEpoch).format('h:mmA');

      let assetData = {
        energy: 0,
        max: 100,
      };
      let login;
      try {
        login = await userLogin(account);
        if (!login?.username) login = await userLogin(account);
        await setCookie(account);
        const energyData = await getEnergy(account);

        assetData.energy = energyData.asset.qty;
        //assetData.max = energyData.asset.asset_type.maximum;
      } catch (e) {
        console.log(e);
      }

      wallet = {
        account,
        balance: await getBalance(account),
        balanceBNB: await getBalanceBNB(account),
        balanceHANDS: await getBalanceHANDS(account),
        claimableBNB: await getClaimableBNB(account),
        claimedBNB: await getClaimedBNB(account),
        nextClaimDateEpoch,
        nextClaimDate,
        reinvested: await getReinvested(account),
        collectRewards: async (percentageBNB) => {
          return await collectRewards(account, web3, percentageBNB);
        },
        buyEnergy: async (amount, token) => {
          return await buyEnergy(account, web3, amount, token);
        },
        energy: assetData.energy,
        //energyMax: assetData.max,
        username: login.username,
        loginmsg: login.message,
        getApproval: async (contractAddress, token) => {
          return await getApproval(account, web3, contractAddress, token);
        },
        approveContract: async (contractAddress, token) => {
          return await approveContract(
            account,
            web3,
            contractAddress,
            token,
            wallet
          );
        },
        mintHodlHands: async (amount) => {
          return await mintHodlHands(account, web3, amount);
        },
      };

      /*
      if (!wallet.balance) {
        throw new Error('No HODL in this wallet');
      }
        */

      localStorageSet('walletData', wallet);
      setWallet(wallet);
    }
  };

  const unsetWalletAddress = (web3) => {
    if (web3 && web3.currentProvider && web3.currentProvider.disconnect) {
      web3.currentProvider.disconnect();
    }
    localStorage.removeItem('provider');
    window.sessionStorage.removeItem('walletAddress');
    setWallet(null);
    setConnected(false);
    // remove cookie
    if (
      document.cookie.split(';').some((c) => {
        return c.trim().startsWith('k=');
      })
    ) {
      document.cookie = 'k=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }
  };

  const setBscData = async () => {
    const bsc = {
      contractHODL: await getBalance(CONTRACTADDRESS),
      bnbUsdPrice: await getBnbUsdPrice(),
      burnWalletHODL: await getBurnWalletHODL(),
      circulatingSupply: await getCirculatingSupply(),
      claimBNBLimit: await getClaimBNBLimit(),
      hodlers: await getHodlers(),
      hodlUsdPrice: await getHodlUsdPrice(),
      hodlUsdPriceHistorical: await getHodlUsdPriceHistorical(),
      liquidityPoolBNB: await getLiquidityPoolBNB(),
      liquidityPoolHODL: await getLiquidityPoolHODL(),
      liquidityPoolUSD: await getLiquidityPoolUSD(),
      projectFunds: await getProjectFunds(),
      projectFundsUSD: await getProjectFundsUSD(),
      rewardPoolShare: await getRewardPoolShare(),
      // reinvestBonus: await (async () => {
      //   try {
      //     const hodlBonus = await getReinvestBonus();

      //     reinvestTokens.hodl.description =
      //       reinvestTokens.hodl.description.replace(/\{BONUS\}/g, hodlBonus);

      //     // reinvestTokens.hodlx.description =
      //     //   reinvestTokens.hodlx.description.replace(/\{BONUS\}/g, hodlx);

      //     return hodlBonus;
      //   } catch (error) {
      //     return {};
      //   }
      // })(),
      reinvestLimit: await getReinvestLimit(),
      rewardPoolBNB: await getRewardPoolBNB(),
      rewardPoolHardcap: await getRewardPoolHardcap(),
      totalClaimedBNB: await getTotalClaimedBNB(),
      rewardClaimPeriod: await getRewardClaimPeriod(),
    };

    localStorageSet('bscData', bsc);
    setBsc(bsc);
  };

  const collectRewards = async (account, web3, percentageBNB) => {
    const data = contract.methods.redeemRewards(percentageBNB);
    const gas = await data.estimateGas({ from: account });
    const gasPrice = await web3.eth.getGasPrice();

    const tx = {
      from: account,
      to: CONTRACTADDRESS,
      data: data.encodeABI(),
      gasPrice:
        gasPrice < 5000000000
          ? web3.utils.toHex(gasPrice)
          : web3.utils.toHex(3000000000),
      gasLimit: web3.utils.toHex(gas + 50000),
    };

    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(tx)
        .once('transactionHash', (hash, error) => {
          if (error) {
            reject(error);
          }
        })
        .once('confirmation', (confirmationNumber, receipt) => {
          setBscData();
          resolve({
            confirmationNumber,
            receipt,
          });
        })
        .on('error', (error) => {
          reject(error);
        });
    });
  };

  const addEnergy = async (account, amount) => {
    const body = {
      wallet: account,
      asset: 'energy',
      source: 'on chain HODL',
      qty: amount,
    };

    const rawResponse = await fetch('/api/p2e/encrypt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: JSON.stringify(body) }),
      credentials: 'include',
    });
    const fullData = await rawResponse.text();

    fetch('https://rest.hodlbot.org/api/ast/add', {
      method: 'POST',
      headers: {
        k: fullData,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === 'ok') {
          return;
        } else {
          console.log('Adding Energy failed');
          console.log(responseData);
          return;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        console.log('Adding Energy failed');
        return;
      });
  };

  const buyEnergy = async (account, web3, amount, token) => {
    const energyContract =
      web3 && new web3.eth.Contract(energyABI, BUYENERGYADDRESS);
    const data = energyContract.methods.purchaseEnergy(token, amount);
    let value = 0;

    if (token === WBNBADDRESS) {
      value = (
        (await energyContract.methods.getBnbAmount(amount).call()) * 1.01
      ).toFixed(0);
    } else {
      const requiredTokens = await energyContract.methods.getTokenAmount(
        token,
        amount
      );
      let balance = 0;
      if (token === CONTRACTADDRESS) {
        balance = await contract.methods.balanceOf(account).call();
      }

      if (Number(balance) < Number(requiredTokens)) {
        throw new Error('insufficient funds');
      }
    }

    const gas = await data.estimateGas({ from: account, value: value });
    const gasPrice = await web3.eth.getGasPrice();

    var tx = {
      from: account,
      to: BUYENERGYADDRESS,
      data: data.encodeABI(),
      value: value,
      gasPrice:
        gasPrice < 5000000000
          ? web3.utils.toHex(gasPrice)
          : web3.utils.toHex(3000000000),
      gasLimit: web3.utils.toHex(gas + 10000),
    };

    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(tx)
        .once('transactionHash', (hash, error) => {
          if (error) {
            reject(error);
          }
        })
        .once('confirmation', (confirmationNumber, receipt) => {
          addEnergy(account, amount).then(() => {
            resolve({
              confirmationNumber,
              receipt,
            });
          });
        })
        .on('error', (error) => {
          reject(error);
        });
    });
  };

  const getApproval = async (account, web3, contractAddress, token) => {
    const contract = web3 && new web3.eth.Contract(bnbABI, token);
    const data = contract.methods.allowance(account, contractAddress);
    return new Promise((resolve, reject) => {
      web3.eth
        .call({
          to: token,
          data: data.encodeABI(),
        })
        .then((res) =>
          resolve(
            web3.utils.fromWei(web3.utils.hexToNumberString(res)),
            'ether'
          )
        )
        .catch((error) => reject(error));
    });
  };

  const approveContract = async (
    account,
    web3,
    contractAddress,
    token,
    wallet
  ) => {
    const contract = web3 && new web3.eth.Contract(hodlABI, token);
    let amount = web3.utils.toWei(wallet.balance.toString(), 'ether');
    const data = contract.methods.approve(contractAddress, amount.toString());
    const gas = await data.estimateGas({ from: account });
    const gasPrice = await web3.eth.getGasPrice();

    const tx = {
      from: account,
      to: token,
      data: data.encodeABI(),
      gasPrice:
        gasPrice < 5000000000
          ? web3.utils.toHex(gasPrice)
          : web3.utils.toHex(3000000000),
      gasLimit: web3.utils.toHex(gas + 10000),
    };

    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(tx)
        .once('transactionHash', (hash, error) => {
          if (error) {
            reject(error);
          }
        })
        .once('confirmation', (confirmationNumber, receipt) => {
          resolve({
            confirmationNumber,
            receipt,
          });
        })
        .on('error', (error) => {
          reject(error);
        });
    });
  };

  const mintHodlHands = async (account, web3, amount) => {
    const buyNFTcontract = new web3.eth.Contract(
      buyHodlhandAbi,
      BUYHODLHANDCONTRACT
    );

    const value = (1000000000000000000 * vars.bnbPerNft * amount).toFixed(0);

    const data = buyNFTcontract.methods.purchase(amount);
    const gas = await data.estimateGas({ from: account, value: value });
    const gasPrice = await web3.eth.getGasPrice();

    const tx = {
      from: account,
      to: BUYHODLHANDCONTRACT,
      data: data.encodeABI(),
      value: value,
      gasPrice:
        gasPrice < 5000000000
          ? web3.utils.toHex(gasPrice)
          : web3.utils.toHex(3000000000),
      gasLimit: web3.utils.toHex(gas + 10000),
    };

    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(tx)
        .once('transactionHash', (hash, error) => {
          if (error) {
            reject(error);
          }
        })
        .once('confirmation', (confirmationNumber, receipt) => {
          resolve({
            confirmationNumber,
            receipt,
          });
        })
        .on('error', (error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    setBscData();
  }, []);

  return (
    <Web3Context.Provider
      value={{
        activate,
        bsc,
        connect,
        disconnect: unsetWalletAddress,
        wallet,
        reinvestTokens,
        connected,
        isConnecting,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const getTokenPrices = async () => {
  let oneEther = web3.utils.toWei('1', 'ether');
  let router = await new web3.eth.Contract(pancakeSwapAbi, PCSROUTER);

  let amountsOut = await router.methods
    .getAmountsOut(oneEther, [WBNBADDRESS, USDADDRESS])
    .call();
  let bnb = web3.utils.fromWei(amountsOut[1]);

  amountsOut = await router.methods
    .getAmountsOut(oneEther, [CONTRACTADDRESS, WBNBADDRESS, USDADDRESS])
    .call();
  let hodl = web3.utils.fromWei(amountsOut[2]);
  return { bnb, hodl };
};

export const getCollectTokens = async (bnb) => {
  let bnbEther = web3.utils.toWei(
    Number(bnb)
      .toFixed(18)
      .replace(/(?<=\.\d*[1-9])0+$|\.0*$/, ''),
    'ether'
  );
  let router = await new web3.eth.Contract(pancakeSwapAbi, PCSROUTER);
  let amountsOut = await router.methods
    .getAmountsOut(bnbEther, [WBNBADDRESS, CONTRACTADDRESS])
    .call();

  return web3.utils.fromWei(amountsOut[1], 'ether');
};

export const getBuyEnergyPrices = async (token, amount) => {
  const energyContract =
    web3 && new web3.eth.Contract(energyABI, BUYENERGYADDRESS);
  const usdRaw = await energyContract.methods.price(amount).call();
  const stringUsd = web3.utils.toBN(usdRaw).toString();
  const usd = Number(web3.utils.fromWei(stringUsd, 'ether'));

  let tokenAmount;
  if (token !== WBNBADDRESS) {
    const tokenAmountRaw = await energyContract.methods
      .getTokenAmount(token, amount)
      .call();
    const stringTokenAmount = web3.utils.toBN(tokenAmountRaw).toString();
    tokenAmount = Number(
      web3.utils.fromWei(stringTokenAmount, 'ether')
    ).toFixed(0);
  } else {
    const bnbRaw = await energyContract.methods.getBnbAmount(amount).call();
    const stringBNB = web3.utils.toBN(bnbRaw).toString();
    tokenAmount = Number(web3.utils.fromWei(stringBNB, 'ether')).toFixed(5);
  }
  return { usd, tokenAmount };
};

export const getBnbUsdPrice = async () => {
  const { bnb } = await getTokenPrices();
  return bnb;
};

export const getBurnWalletHODL = async () => {
  const amount = await contract.methods
    .balanceOf('0x000000000000000000000000000000000000dEaD')
    .call();

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getCirculatingSupply = async () => {
  const [burned, contractBalance, projectFunds, liquidity] = await Promise.all([
    getBurnWalletHODL(),
    getBalance(CONTRACTADDRESS),
    getProjectFunds(),
    getLiquidityPoolHODL(),
  ]);

  // Summiere die projectFunds-Werte
  const totalProjectFunds = Object.values(projectFunds).reduce(
    (acc, balance) => acc + balance,
    0
  );

  return 10000000000 - burned - contractBalance - totalProjectFunds - liquidity;
};

export const getClaimBNBLimit = async () => {
  // const amount = await contract.methods.claimBNBLimit().call();
  return web3.utils.fromWei('71305800000000', 'ether'); // 5 cents
};

export const getMinTokensToStack = async () => {
  const string = await contract.methods.minTokensToStack().call();
  return web3.utils.fromWei(string, 'ether');
};

export const getHodlers = async () => {
  // const { holders_count } = await fetch(`${vars.apiHost}/hodl-bot/data`).then(
  //   (response) => response.json()
  // );
  const rawResponse = await fetch('/api/tokeninfo/holdercount', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await rawResponse.json();
  return Number(data.count);
};

export const getHodlUsdPrice = async () => {
  const usdValue = await contract.methods
    .getTokensValue('1000000000000000000')
    .call();
  return web3.utils.fromWei(usdValue, 'ether');
};

export const getHodlUsdPriceHistorical = async () => {
  // Current Value
  const usdValue = await contract.methods
    .getTokensValue('1000000000000000000')
    .call();
  const currentvalue = Number(web3.utils.fromWei(usdValue, 'ether'));

  const pricesRaw = await fetch('/api/tokeninfo/prices', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const prices = await pricesRaw.json();
  return {
    hodlUsdPrice24h:
      (currentvalue /
        Number(prices.twentyFourHoursAgo?.HODL_PCS || currentvalue)) *
        100 -
      100,
    hodlUsdPrice7d:
      (currentvalue / Number(prices.sevenDaysAgo?.HODL_PCS || currentvalue)) *
        100 -
      100,
  };
};

export const getLiquidityPoolBNB = async () => {
  const contract = new web3.eth.Contract(bnbABI, WBNBADDRESS);
  const amounts = await mapAsync(LPADDRESSES, async (address) => {
    const balance = await contract.methods.balanceOf(address).call();
    const string = web3.utils.toBN(balance).toString();
    return Number(web3.utils.fromWei(string, 'ether'));
  });
  return amounts.reduce((total, amount) => total + amount, 0);
};

export const getLiquidityPoolHODL = async () => {
  const amounts = await mapAsync(LPADDRESSES, async (address) => {
    const balance = await contract.methods.balanceOf(address).call();
    const string = web3.utils.toBN(balance).toString();
    return Number(web3.utils.fromWei(string, 'ether'));
  });
  return amounts.reduce((total, amount) => total + amount, 0);
};

export const getLiquidityPoolUSD = async () => {
  const bnbPrice = await getBnbUsdPrice();
  const hodlPrice = await getHodlUsdPrice();
  const bnb = await getLiquidityPoolBNB();
  const hodl = await getLiquidityPoolHODL();

  return bnb * bnbPrice + hodl * hodlPrice;
};

export const getProjectFunds = async () => {
  const promises = projectFundsWallets.map(async (wallet) => {
    const balance = await getBalance(wallet.address);
    return { [wallet.name]: balance };
  });
  const results = await Promise.all(promises);
  return results.reduce((acc, current) => {
    return { ...acc, ...current };
  }, {});
};

export const getProjectFundsUSD = async () => {
  const wallets = vars.projectFunds
    .split('\n')
    .map((line) => line.match(/0x[a-f0-9]{40}/i)[0]);

  const bnbPrice = await getBnbUsdPrice();
  const contract = new web3.eth.Contract(busdABI, BUSDADDRESS);

  const promises = wallets.map(async (wallet) => {
    const bnbAmount = await web3.eth.getBalance(wallet);
    const bnbString = web3.utils.toBN(bnbAmount).toString();
    const bnb = Number(web3.utils.fromWei(bnbString, 'ether'));

    const busdAmount = await contract.methods.balanceOf(wallet).call();
    const busdString = web3.utils.toBN(busdAmount).toString();
    const usd = Number(web3.utils.fromWei(busdString, 'ether'));

    return bnb * bnbPrice + usd;
  });

  return Promise.all(promises).then((amounts) => {
    let funds = vars.estInvestmentFunds || 0;
    for (const amount of amounts) {
      funds += amount;
    }
    return funds;
  });
};

export const getRewardPoolShare = async () => {
  const amount = await contract.methods.getRewardPoolShare().call();

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getRewardPoolBNB = async () => {
  const amount = await web3.eth.getBalance(CONTRACTADDRESS);
  const string = web3.utils.toBN(amount).toString();
  return web3.utils.fromWei(string, 'ether');
};

export const getRewardPoolHardcap = async () => {
  const amount = await contract.methods.bnbRewardPoolCap().call();

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getTotalClaimedBNB = async () => {
  const amount = await contract.methods.totalBNBClaimed().call();

  const string = web3.utils.toBN(amount).toString();
  return (
    Number(web3.utils.fromWei(string, 'ether')) +
    parseFloat(vars.additionalBnbPaidOut || 0)
  );
};
export const getRewardClaimPeriod = async () => {
  const period = await contract.methods.rewardClaimPeriod().call();
  return Number(period);
};

export const getBalance = async (account) => {
  const amount = await contract.methods.balanceOf(account).call();

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getBalanceBNB = async (account) => {
  const amount = await web3.eth.getBalance(account);

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getBalanceHANDS = async (account) => {
  const handsContract = new web3.eth.Contract(hodlhandsAbi, HODLHANDSCONTRACT);
  const amount = await handsContract.methods.balanceOf(account).call();

  return Number(amount);
};

export const getClaimableBNB = async (account) => {
  const amount = await contract.methods.getCurrentBNBReward(account).call();

  const string = web3.utils.toBN(amount).toString();
  return Number(Number(web3.utils.fromWei(string, 'ether')));
};

export const getClaimedBNB = async (account) => {
  const amount = await contract.methods.userBNBClaimed(account).call();

  const string = web3.utils.toBN(amount).toString();
  return Number(web3.utils.fromWei(string, 'ether'));
};

export const getNextClaimDate = (account) => {
  return contract.methods.nextClaimDate(account).call();
};

export const getReinvested = async (account) => {
  try {
    const amount = await contract.methods.userReinvested(account).call();
    const string = web3.utils.toBN(amount).toString();
    return Number(web3.utils.fromWei(string, 'ether'));
  } catch (error) {
    console.log(error);
  }
};

export const getReinvestBonus = async () => {
  const HODLBonus = await contract.methods.reinvestBonusCycle().call();
  return HODLBonus;
};

export const getReinvestLimit = async () => {
  // const amount = await contract.methods.reinvestLimit().call();
  return web3.utils.fromWei('212024000000000', 'ether'); // 15cents
};

export const getEnergy = async (account) => {
  try {
    const res = await fetch(`/api/p2e/energy?address=${account}`);
    return await res.json();
  } catch (e) {
    console.log(e);
  }
};

export const userLogin = async (account) => {
  try {
    const res = await fetch(`/api/p2e/login?address=${account}`, {
      method: 'GET',
      mode: 'cors',
    });
    return await res.json();
  } catch (e) {
    console.log(e);
  }
};

const setCookie = async (wallet) => {
  const walletObj = {
    wallet: wallet,
  };
  const rawResponse = await fetch('/api/p2e/encrypt', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message: JSON.stringify(walletObj) }),
    credentials: 'include',
  });
  const encryptedWallet = await rawResponse.text();

  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000); //1 day
  let expires = 'expires=' + d.toUTCString();
  document.cookie =
    'k=' + encryptedWallet + ';' + expires + ';path=/;SameSite=Strict';
};
