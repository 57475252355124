import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import * as hookstate from '@hookstate/core';

import vars from 'variables';

import { formatNumber, localStorageGet, localStorageSet } from 'utils';

import {
  getBalance,
  getReinvested,
  getClaimedBNB,
  getClaimableBNB,
  getBnbUsdPrice,
  getHodlUsdPrice,
  getNextClaimDate,
} from 'web3';

const DashboardContext = createContext({
  addresses: {},
  balance: 0,
  claimableBNB: 0,
  loadingOverlay: {},
  reinvestedHODL: 0,
  reinvestments: {},
  rewarded: 0,
  rewards: {},
  transactions: {},
});

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const addresses = hookstate.useState({});
  const [balance, setBalance] = useState(0);
  const [claimableBNB, setClaimableBNB] = useState(0);
  const [reinvestedHODL, setReinvestedHODL] = useState(0);
  const [reinvestments, setReinvestments] = useState({});
  const [rewarded, setRewarded] = useState(0);
  const [rewards, setRewards] = useState({});
  const [stats, setStats] = useState({});
  const [transactions, setTransactions] = useState({});

  const claimEpochs = hookstate.useState([]);
  const loadingOverlay = useRef();

  useEffect(() => {
    const data = localStorageGet('dashboardData', {});

    addresses.set(data.addresses || {});
    setBalance(data.balance || 0);
    setClaimableBNB(data.claimableBNB || 0);
    setReinvestedHODL(data.reinvestedHODL || 0);
    setReinvestments(data.reinvestments || {});
    setRewarded(data.rewarded || 0);
    setRewards(data.rewards || {});
    setStats(data.stats || {});
    setTransactions(data.transactions || {});

    updateAll();
  }, []);

  const updateAll = () => {
    Object.entries(addresses.get()).forEach(([account, selected]) => {
      update(account);
    });
  };

  const update = async (address) => {
    if (address) {
      rewards[address] = await fetchTransactions('rewards', address);
      reinvestments[address] = await fetchTransactions(
        'reinvestments',
        address
      );
      stats[address] = Object.assign({
        //await fetchData('stats', address), {
        balance: await getBalance(address),
        reinvested: await getReinvested(address),
        rewarded: await getClaimedBNB(address),
        claimableBNB: await getClaimableBNB(address),
      });
      transactions[address] = await fetchTransactions('transactions', address);
    }

    let accBalance = 0;
    let accClaimableBNB = 0;
    let accReinvestedHODL = 0;
    let accRewarded = 0;

    Object.entries(addresses.get()).forEach(([account, selected]) => {
      if (selected) {
        const accountStats = stats[account];
        accBalance += accountStats.balance;
        accClaimableBNB += accountStats.claimableBNB;
        accReinvestedHODL += accountStats.reinvested;
        accRewarded += accountStats.rewarded;
      }
    });

    setBalance(accBalance);
    setClaimableBNB(accClaimableBNB);
    setReinvestedHODL(accReinvestedHODL);
    setRewarded(accRewarded);

    fetchClaimEpochs();

    localStorageSet('dashboardData', {
      addresses: addresses.get(),
      balance: accBalance,
      claimableBNB: accClaimableBNB,
      reinvestedHODL: accReinvestedHODL,
      rewarded: accRewarded,
      rewards,
      reinvestments,
      stats,
      transactions,
    });
  };

  const addWalletAddress = (address) => {
    loadingOverlay.current.show('Fetching Info');
    address = address.toLowerCase();
    addresses[address].set(true);
    update(address);
  };

  const removeWalletAddress = (address) => {
    address = address.toLowerCase();
    if (Object.keys(addresses.get()).indexOf(address) !== -1) {
      addresses[address].set(hookstate.none);
      update();
    }
  };

  const selectWalletAddress = (address, selected) => {
    address = address.toLowerCase();
    if (Object.keys(addresses.get()).indexOf(address) !== -1) {
      addresses[address].set(selected);
      update();
    }
  };

  const fetchClaimEpochs = () => {
    setTimeout(() => {
      const epochs = [];
      Promise.all(
        Object.entries(addresses.get()).map(async ([address, selected]) => {
          if (selected) {
            epochs.push({
              address,
              epoch: Number(await getNextClaimDate(address)),
              amount: Number(await getClaimableBNB(address)),
            });
          }
        })
      ).then(() => {
        claimEpochs.set(epochs.sort(({ epoch: a }, { epoch: b }) => a - b));
        loadingOverlay.current?.hide();
      });
    }, 250);
  };

  return (
    <DashboardContext.Provider
      value={{
        addresses,
        balance,
        claimableBNB,
        claimEpochs,
        loadingOverlay,
        reinvestedHODL,
        reinvestments,
        rewarded,
        rewards,
        transactions,
        addWalletAddress,
        removeWalletAddress: (address) => removeWalletAddress(address),
        selectWalletAddress: (address) => selectWalletAddress(address, true),
        deselectWalletAddress: (address) => selectWalletAddress(address, false),
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

const fetchTransactions = async (type, address) => {
  const getUsdPrice = {
    rewards: getBnbUsdPrice,
    reinvestments: getHodlUsdPrice,
    transactions: getHodlUsdPrice,
  }[type];

  const usdPrice = await getUsdPrice();
  const txsRaw = await fetch(`/api/tokeninfo/${type}?address=${address}`); //await fetchData(type, address);
  const txs = await txsRaw.json();

  return txs.map(([block, transaction, amount, timestamp, from, to]) => ({
    amount: parseFloat(amount),
    value: '$' + formatNumber(amount * usdPrice, 2),
    timestamp:
      typeof timestamp === 'number'
        ? new Date(timestamp * 1000).toISOString()
        : timestamp,
    tx: transaction?.toLowerCase() || '',
    block,
    type: from === address ? 'OUT' : 'IN',
  }));
};

const fetchData = (type, address) => {
  const versionPrefix = ['transactions'].includes(type) ? '/v2' : '';

  return fetch(`${vars.apiHost}${versionPrefix}/-/${type}/${address}`)
    .then((response) => response.json())
    .then((data) => data);
};
