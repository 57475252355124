import React, { Fragment, useEffect, useRef } from 'react';
import { Root, Message } from './style';

import { useState } from '@hookstate/core';

import Modal from 'components/Common/Modal';

import hodlapp from 'assets/Claim/Connect/hodlapp.svg';
import metamask from 'assets/Claim/Connect/metamask.svg';
import trustwallet from 'assets/Claim/Connect/trustwallet.svg';
import safepal from 'assets/Claim/Connect/safepal.svg';
import wconnect from 'assets/Claim/Connect/walletconnect.svg';

import { useWeb3, INJECTED, WALLETCONNECT } from 'web3';
// import { encrypt, getKey } from 'utils';

import vars from 'variables';

const Connect = ({ header, caption }) => {
  const error = useState('');
  const subError = useState('');
  const modal = useRef();

  const { connect, activate, isConnecting } = useWeb3();

  const closeMenu = () => {
    try {
      document.getElementById('menuCheckbox').checked = false;
      document.body.classList.remove('noscroll');
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = () => {
    error.set('');
    subError.set('');
    modal.current.show();
  };

  const web3Connect = (loginType) => {
    connect(loginType)
      .then(() => modal.current.hide())
      .catch((e) => {
        if (e.message !== 'closed') {
          error.set(e.message);
          subError.set(e.submessage);
          modal.current.show();
        }
      });
  };

  const isPlayToEarn = () =>
    window.location.pathname.startsWith('/play-to-earn');

  useEffect(() => {
    if (
      header &&
      document.cookie.split(';').some((c) => {
        return c.trim().startsWith('k=');
      }) &&
      localStorage.getItem('provider') &&
      !isConnecting &&
      isPlayToEarn()
    ) {
      connect();
    }
  }, []);

  return (
    <Fragment>
      <a
        className={`button connect blue hidable ${
          header ? '' : 'hero-cta-wrapper__hero-cta'
        }`}
        onClick={() => {
          closeMenu();
          showModal();
        }}
      >
        {caption ?? 'Connect'}
      </a>
      <Modal className="scaled-07" usedRef={modal}>
        {error.get() ? (
          <Message className="error">
            <h4>
              <span>Something went wrong</span>
            </h4>
            <h2>Oops! {error.get()}</h2>
            {subError.get() && <h3>{subError.get()}</h3>}
            <a
              className="button blue logo"
              href={vars.links.buy}
              target="_blank"
              rel="noreferrer"
            >
              Buy HODL now
            </a>
          </Message>
        ) : (
          <Message>
            <h3>Connect your wallet</h3>
            <ul>
              {window.ethereum?.isHODLApp ? (
                <li onClick={() => web3Connect(INJECTED)}>
                  <strong>HODL App</strong>
                  <img src={hodlapp} />
                </li>
              ) : null}
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>MetaMask</strong>
                <img src={metamask} />
              </li>
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>Trust Wallet</strong>
                <img src={trustwallet} />
              </li>
              <li onClick={() => web3Connect(INJECTED)}>
                <strong>SafePal</strong>
                <img src={safepal} />
              </li>
              <li className="hr">
                <span>or</span>
              </li>
              <li onClick={() => web3Connect(WALLETCONNECT)}>
                <strong>WalletConnect</strong>
                <img src={wconnect} />
              </li>
            </ul>
          </Message>
        )}
      </Modal>
    </Fragment>
  );
};

export default Connect;
